import React, { Component } from 'react';

class Question extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="columns">
                <div className="column">
                    {
                        this.props.options.map((option) =>
                        <div className="columns" key={option.id}>
                            <div className="column">
                                <button className="button is-fullwidth" onClick={(e) => this.props.select("reason", option.id)}>
                                    { option.text }
                                </button>
                            </div>
                        </div>
                        )
                    }
                </div>
            </div>
        );
    }
}

export default Question;
